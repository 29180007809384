/* You can add global styles to this file, and also import other style files */
// @import '~ngx-sharebuttons/themes/circles/circles-dark-theme';
@use 'src/styles/index' as s;
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
* {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: 'Open Sans';
  src: url(./app/../assets/Open_Sans.ttf);
}

main {
  overflow-x: hidden;
  /* background: rgba(255,255,255,1);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%); */
}

main::-webkit-scrollbar {
  display: none;
}

main::-webkit-scrollbar-thumb {
  display: none;
}

main {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100dvh !important;
  width: 100dvw !important;
}

app-root {
  height: 100dvh !important;
  width: 100dvw !important;
}

.router-outlet {
  height: 100dvh !important;
  width: 100dvw !important;
}

.router-outlet {
  height: 100dvh !important;
  width: 100dvw !important;
}

.mainWidth {
  max-width: 600px;
}
// .primary-light {
//   background-color: #7030a0;
// }

// .secondary-text-l {
//   color: #7030a0;
// }

// .border-secondary {
//   border-color: #7030a0;
// }

// .font-color-primary-header-l {
//   color: #7030a0;
// }

.primary-light {
  background-color: rgba(0, 0, 0, 0.76);
}

.secondary-text-l {
  color: rgba(0, 0, 0, 0.76);
}

.border-secondary {
  border-color: rgba(0, 0, 0, 0.76);
}

.font-color-primary-header-l {
  color: rgba(0, 0, 0, 0.76);
}
.font-color-l {
  color: #1f1f1f;
}

.font-color-subheader-l {
  color: #9c9c9c;
}

.font-color-active-l {
  color: rgba(0, 0, 0, 0.76);
}
.grey-light {
  color: #718096;
}
.gray-l {
  outline-color: #9c9c9c;
}

.border-gray-l {
  border-color: #9c9c9c;
}

.border-primary {
  border-color: rgba(0, 0, 0, 0.76);
}

.centered-block {
  left: 50%;
  transform: translateX(-50%);
}
.bg-purple-700 {
  background-color: rgba(0, 0, 0, 0.76);
}

.bg.purple-400 {
  background-color: #bebebe;
}

.bg-purple-500 {
  background-color: #868686;
}

.text-purple-700 {
  color: rgba(0, 0, 0, 0.76);
}

.text-gray-700 {
  color: #575756;
}

.text-gray-600 {
  color: #a3a3a3;
}

.text-gray-500 {
  color: #878787;
}

.text-gray-800 {
  color: #4a4a49;
}

.text-white {
  color: #ffff;
}
.text-purple-800 {
  color: rgba(0, 0, 0, 0.76);
}
.bg-purple-600 {
  background-color: rgba(0, 0, 0, 0.76);
}
.hover:bg-purple-600 {
  background-color: rgba(0, 0, 0, 0.56);
}
.bg-purple-700 {
  background-color: black;
}
.bg-purple-500 {
  color: rgba(0, 0, 0, 0.76);
}
.bg-default-500 {
  background-color: #ed8936;
}
.bg-wco-500 {
  background-color: #575756;
}
.border-purple-700 {
  border-color: #a3a3a3;
}

.degrade {
  position: relative;
  top: -9px;
  height: 11px;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    rgba(255, 255, 255, 1) 100%
  );
  opacity: 0.5;
}
.degradeBig {
  opacity: 0.6;
  position: absolute;
  top: 252px;
  width: 100%;
  height: 132px;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
.ajustar {
  position: relative;
  top: -235px;
}
.spinner {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: 50%;
  margin-left: -18px;
  z-index: 1000;
}
.loader {
  width: 3rem;
  height: 3rem;
}
// .rounded-full{

// }
.shad {
  box-shadow: 0px 1px 13px 1px rgba($color: #000000, $alpha: 0.2);
}
