@use "colors" as *;

/* DOCS: Roboto */
/* DOCS: Roboto Bold(700) */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('./fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-Bold.woff') format('woff');
}

/* DOCS: Roboto Bold(700) Italic */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('./fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-BoldItalic.woff') format('woff');
}

/* DOCS: Roboto Medium(500) */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('./fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-Medium.woff') format('woff');
}

/* DOCS: Roboto Medium(500) Italic */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
  url('./fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-MediumItalic.woff') format('woff');
}

/* DOCS: Roboto Regular(400) */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
  url('./fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-Regular.woff') format('woff');
}

/* DOCS: Roboto Regular(400) Italic */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('./fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-Italic.woff') format('woff');
}

/* DOCS: Roboto Light(300) */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
  url('./fonts/Roboto/Roboto-Light.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-Light.woff') format('woff');
}

/* DOCS: Roboto Light(300) Italic */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-stretch: normal;
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('./fonts/Roboto/Roboto-Light.woff2') format('woff2'),
  url('./fonts/Roboto/Roboto-Light.woff') format('woff');
}

/* DOCS: Open Sans */
/* DOCS: Open Sans Bold(700) */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('./fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-Bold.woff') format('woff');
}

/* DOCS: Open Sans Bold(700) Italic*/
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('./fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff');
}

/* DOCS: Open Sans Medium(500) */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Open Sans Medium'), local('OpenSans-Medium'),
  url('./fonts/OpenSans/OpenSans-Medium.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-Medium.woff') format('woff');
}

/* DOCS: Open Sans Medium(500) Italic */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Open Sans Medium Italic'), local('OpenSans-MediumItalic'),
  url('./fonts/OpenSans/OpenSans-MediumItalic.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-MediumItalic.woff') format('woff');
}

/* DOCS: Open Sans Regular(400) */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('./fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-Regular.woff') format('woff');
}

/* DOCS: Open Sans Regular(400) Italic */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('./fonts/OpenSans/OpenSans-Italic.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-Italic.woff') format('woff');
}

/* DOCS: Open Sans Light(300) */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('./fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-Light.woff') format('woff');
}

/* DOCS: Open Sans Light(300) Italic */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('./fonts/OpenSans/OpenSans-LightItalic.woff2') format('woff2'),
  url('./fonts/OpenSans/OpenSans-LightItalic.woff') format('woff');
}

$_font-family-roboto: 'Roboto', sans-serif;
$_font-family-open-sans: 'Open Sans', sans-serif;
$primary-family: $_font-family-open-sans;
$secondary-family: $_font-family-roboto;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;
$font-weight-light: 300;
$font-style-normal: normal;
$font-style-italic: italic;

$browser-context: 16;

$font-size-8: (
  'font-size': 8,
  'line-height': 11
);
$font-size-11: (
  'font-size': 11,
  'line-height': 14
);
$font-size-12: (
  'font-size': 12,
  'line-height': 16
);
$font-size-14: (
  'font-size': 14,
  'line-height': 18
);
$font-size-16: (
  'font-size': 16,
  'line-height': 20
);
$font-size-17: (
  'font-size': 17,
  'line-height': 22
);
$font-size-18: (
  'font-size': 18,
  'line-height': 24
);
$font-size-20: (
  'font-size': 20,
  'line-height': 25
);
$font-size-22: (
  'font-size': 22,
  'line-height': 28
);
$font-size-24: (
  'font-size': 24,
  'line-height': 32
);
$font-size-28: (
  'font-size': 28,
  'line-height': 36
);
$font-size-30: (
  'font-size': 30,
  'line-height': 38
);

@mixin font-size(
  $font-family: $primary-family,
  $font-weight: 400,
  $font-size-map: $font-size-16,
  $font-style: normal,
  $color: $color-primary-dark) {
  @include _normalize-font;
  @include _responsive-font-size-new($font-size-map);
  color: $color;
  font-family: $font-family;
  font-style: $font-style;
  font-weight: $font-weight;
}

@mixin _normalize-font {
  font-stretch: normal;
  letter-spacing: normal;
}

@mixin _responsive-font-size-new($font-size-map) {
  @each $property, $value in $font-size-map {
    #{$property}: $value * 1px;
  }
}
