*,
::after,
::before {
  box-sizing: border-box;
  border-width: 0;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: inherit;
  color: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

input {
  overflow: visible;
  padding: 0;
  /*all: unset;*/
}
